<template>
	<div class="container">
		<Intro title="Info & News" :content="paragraphs"></Intro>
		<div class="content">
			<img :src="logo" />
			<div class="info">
				<h2>About the pratice</h2>
        <p>The practice has been established here in Whittlesey since 1985 and our experienced staff are renowned for their high standard of patient care.</p>
        <p>Jigme and Louisa are the third generation of practice principals of the practice, taking over from Gavin and Donya Stokes in 2019. We look forward to welcoming you to the practice and continuing to care for your dental needs.</p>
			</div>
      <div class="pgcontent">
        <div v-for="card in cards" :key="card.title" class="card">
          <h2>{{card.title}}</h2>
          <p>{{card.content}}</p>
        </div>
      </div>
		</div>
		<Cards></Cards>
	</div>
</template>

<style scoped>
	.content{
		background-color: var(--primary-variant);
	}
  .pgcontent {
    background-color: var(--primary);
    padding: 1.5rem;
    color: #fff;
  }
	.info{
		color: var(--primary);
		padding: 0 2rem 1rem 2rem;
	}
	.info-2{
		padding: 1rem 2rem;
		color: var(--background);
		background: var(--primary);
	}
	.info-2 > h2{
		margin: 0;
	}
	img{
		padding: 3rem;
	}
	@media screen and (min-width: 1024px){
    p{

      margin-right: 5rem;
    }
	.content{
		display: flex;
		flex-wrap: wrap;		
	}
	.info-2, img{
		padding: 2rem var(--padding1);
	}
    .card{
      max-width: 50%;
    }
	.info{
		width: 45%;
		font-size: 1rem;
		padding: 2rem 0;
	}
    .pgcontent{
      display: flex;
      flex-wrap: wrap;
      padding: 2rem var(--padding1);
      justify-content: center;
    }
	.info-2{
		width: 100%;
	}
	img{
		width: 20rem;
	}		
	}
  @media screen and (min-width: 375px){
    .pgcontent{
      padding: 1.5rem 2.5rem;
    }
  }
  @media screen and (min-width: 768px){
    .pgcontent{
      padding: 1.5rem 6rem;
    }
  }
	@media screen and (min-width: 1440px) {
        .info-2, img{
            padding: 2rem var(--padding2);
        }
    }
</style>
<script>
import Intro from '../components/Intro.vue';
import logo from '../assets/light/BSDP_DP_WHITTLESEY_SEAL_LOGO.svg'
import Cards from '../sections/Cards.vue';

export default {
    components: {
    Intro,
    Cards
},
    data(){
      return {
        logo,
        cards: [
          {"title": "Payment Methods", "content": "We accept payments in the form of cash as well as all major credit and debit cards. Unfortunately we are no longer able to accept cheques."},
          {"title": "Transport", "content": "There is ample free parking around the surgery, including the Nisa Local over the road. Public transport links by bus and train are available from Peterborough as well as surrounding villages, check with the provider in advance of your appointment."},
          {"title": "Complaints", "content": "If you have any concerns or comments about your experience in our practice, please contact us by telephone or via our contact form. A copy of the complaints procedure is available from reception."},
          {"title": "Patient Confidentiality", "content": "We take patient confidentiality extremely seriously and have robust systems in place to protect all personal information. All patients records are stored securely, and we will not release them to a third party without your permission unless required to do so by law. A copy of our confidentiality policy is available at reception."}
        ],
        paragraphs: ["Here you can read our recent news and get further information.", "We hope that you are entirely satisfied with your dental care. If not, please let us know so that we can improve our service. A copy of the practice complaints procedure can be obtained from reception."],
      }
    }
}

</script>